<template>
  <Header class="header">
      <div class="header__content row h100">
        <div class="header__top">
          <div class="container">
            <div class="row">
              <div class="d-flex justify-between response">
                <a class="header__logo" href="/"><img src="@/assets/images/logo.png" alt="logo"></a>
                <div class="header__user">
                  <router-link class="header__user-link"  to="/profile">
                    <div class="header__user-logo">
                     <img src="@/assets/images/user-logo.png" alt="user-logo">
                    </div>
                    <div class="header__user-name" to="/profile" v-if="profile">
                      Hi, {{ profile.manager_contact_name }}
                    </div>
                  </router-link>
                  <div class="header__user-exit" @click="logoutProfile()"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header__bottom">
          <div class="container">
            <div class="row">
              <div class="d-flex justify-between response">
                <ul class="header__menu">
                  <li class="header__menu-item" v-for="(item, index) in menuList" :key="index">
                    <router-link class="link-icon" :to="item.routeLink">
                      <inline-svg :src="require(`@/assets/images/svg/${item.icon}`)"></inline-svg>
                      {{ item.title }}
                    </router-link>
                  </li>
                </ul>
                <ul class="header__menu">
                  <li class="header__menu-item">
                    <router-link class="link-icon white-icon" to="/cart">
                      <inline-svg :src="require(`@/assets/images/svg/basket-ic.svg`)"></inline-svg>
                      <span class="header__menu-count" v-if="cartProducts && cartProducts.data.length > 0">{{cartProducts.data.length}}</span>
                    </router-link>
                  </li>
                  <li class="header__menu-item">
                    <router-link class="link-icon white-icon" to="/wishlist">
                      <inline-svg :src="require(`@/assets/images/svg/wish-ic.svg`)"></inline-svg>
                      <span class="header__menu-count" v-if="wishlist && wishlist.length > 0">{{wishlist.length}}</span>
                    </router-link>
                  </li>
                  <li class="header__menu-item">
                    <router-link class="link-icon white-icon" to="/orders/sell-orders">
                      <inline-svg :src="require(`@/assets/images/svg/ok-ic.svg`)"></inline-svg>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Header>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

  export default {
    data(){
      return{
        activePage: 1,
        menuList:[
          {
            title:'Dashboard',
            routeLink:'/dashboard',
            icon:'dashboard-ic.svg',
          },
          {
            title:'Manage Products',
            routeLink:'/products',
            icon:'products-ic.svg',
          },
          {
            title:'Manage Orders',
            routeLink:'/orders',
            icon:'orders-ic.svg',
          },
          {
            title:'Manage Wholesalers',
            routeLink:'/wholesalers',
            icon:'wholesalers-ic.svg',
          },
        ],
      }
    },
    computed:{
      ...mapGetters({
        profile:'profile/profile',
        wishlist:'general/wishlist',
        cartProducts:'product/cartProducts',
      })
    },
    created() {
      if(!this.wishlist){
        this.getWishlist();
      }
      if(!this.cartProducts){
        this.getCartProducts();
      }
    },
    methods:{
      logoutProfile(){
        localStorage.removeItem('userToken');
        this.$router.push('/')
      },
      ...mapActions({
        getWishlist: 'general/getWishlist',
        getCartProducts:'product/getCartProducts'
      }),
      ...mapMutations({
        changeToggleRegistration: 'general/changeToggleRegistration',
        changeToggleLogin: 'general/changeToggleLogin',
      })
    }
  }
</script>

<style lang="scss" scoped>
@import "src/assets/styles/styles";
  .header{
    position: relative;
    &__content{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    &__top{
      padding: 15px;
      display: flex;
      height: 100px;
      justify-content: space-between;
      align-items: center;
      background-color: $blue;
      @media screen and (max-width: 991px) {
        height: auto;
      }
    }
    &__logo{
      img{
        width: 250px;
      }
    }
    &__user{
      color: $white;
      display: flex;
      align-items: center;
    }
    &__user-link{
      display: flex;
      align-items: center;
      padding-right: 15px;
      &.router-link-active{
        background-color: white;
        border-radius: 35px;
        color: $grey;
        .header__user-name{
          color: $grey;

        }
      }
    }
    &__user-logo{
      img{
        height: 100%;
        max-height: 55px;
        max-width: 200px;
        object-fit: contain;
      }
    }
    &__user-name{
      cursor: pointer;
      margin-left: 15px;
      color: $white;
      font-size: 16px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      &:hover{
        text-decoration: underline;
      }
    }
    &__user-exit{
      cursor: pointer;
      width: 17px;
      height: 20px;
      background: url("~@/assets/images/exit-ic.png") no-repeat center;
      background-size: contain;
      margin-left: 40px
    }
    &__bottom{
      height: 75px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      box-shadow: 0px 0px 31px rgba(59, 64, 240, 0.2);
      @media screen and (max-width: 991px) {
        height: auto;
        padding: 15px;
      }
    }
    &__menu{
      display: flex;
      align-items: center;
      margin-bottom: 0;
      @media screen and (max-width: 768px){
        flex-wrap: wrap;
      }
    }
    &__menu-item{
      margin-right: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      position: relative;
      a{
        font-family: 'Inter', Arial;
        font-weight: 400;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    &__menu-count{
      position: absolute;
      right: 0;
      top: -5px;
      font-size: 9px;
      font-family: 'Inter', Arial;
      font-weight: 700;
      color: $white;
      background-color: $grey07;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
